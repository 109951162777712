@charset "utf-8";
$link-color: #eaeaea;
$primary-color: #eeeeee;
@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

.site-title, .masthead__inner-wrap, #page-title {
    font-family: 'Permanent Marker', cursive;
}

.toc .nav__title {
    color: $background-color;
}